#login-page-not-logged-main-container{
    text-align: center;
    /* border: 1px solid black; */
    width: 50vw;
    margin: 3vh auto auto auto;
    padding: 2vh;
    padding-top: 70px;
}

.input-login {
    border: 1px solid #ccc;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
}

#first-string-login-page-not-logged{
    font-size: 5vh;
}

#second-string-login-page-not-logged{
    font-size: 2.2vh;
}

#bottom-text-login-page-not-logged {
    text-align: center;
    margin-top: 20px;
  }

.password-login-input{
    /* margin-bottom: 15vh; */
}

@media screen and (max-width: 900px){
    #login-page-not-logged-main-container{
        margin: 20px 0 0 0;
        width: 100%;
        padding-top: 110px;
    }

    #login-page-login-button {
        width: 100%;
        background-color: blue; /* Replace with your button color */
        color: white;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
}