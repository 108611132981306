.buttonBackgroundModule{
    background-color: rgba(70,90,135,255);
    padding: 7px 18px;
    border: 10px;
    border-radius: 100px;
    font-size: 18px;
    /* margin-top: 20px; */
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}