#dataContainer{
    padding: 5vh;
}

#profile-page-main-container{
    border: 1px solid rgba(0, 0, 0, 0.317);
    border-radius: 8px;
    width: 40vw;
    padding: 2vh 5vh 2vh 5vh;
    margin-bottom: 5vh;
    margin: 5vh auto 5vh auto;
    text-align: center;

}

.intl-tel-input {
  display: table-cell;
}
.intl-tel-input .selected-flag {
  z-index: 4;
}
.intl-tel-input .country-list {
  z-index: 5;
}
.input-group .intl-tel-input .form-control {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
}

#profile-page-user-data-container{
    text-align: left;
}
#profile-page-user-data-container input{
    width: 100%;
    margin-bottom: 3px;
}

.headerText{
    padding-bottom: 5px
}

.profile-page-field-logo{
    margin-left: 5px;
}

#profile-page-save-and-cancel-buttons-container{
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.profile-page-field-logo{
    margin-left: 5px;
    font-size: 20px;
}

@media screen and (max-width: 900px){    
    #profile-page-main-container{
        margin: 0 0 0 0;
        text-align: center;
        padding: 10px;
        border: none;
        border-radius: 8px;
        width: 100%;
        text-align: center;

    }

    #profile-page-main-container div{
        /* padding-bottom: 10px; */
    }

    #profile-page-main-container p{
        margin-bottom: 5px;
    }

    #profile-page-user-data-container{
        text-align: left;
    }

    #profile-page-user-data-container input{
        width: 100%;
        margin-bottom: 3px;
    }

    .headerText{
        padding-bottom: 5px
    }

    .profile-page-field-logo{
        margin-left: 5px;
        font-size: 15px;
    }

    #profile-page-save-and-cancel-buttons-container{
        display: flex;
        width: 100%;
        justify-content: space-around;
    }
}