#not-login-block-main-container {
    /* border: 1px solid black; */
    width: 50vw;
    margin: auto;
    text-align: center;
    margin-top: 10vh;
    font-size: 28px;
    padding-top: 40px;
}

@media screen and (max-width: 900px) {
    #not-login-block-main-container{
        margin: 0;
        width: 100%;
        padding: 110px 20px;
        font-size: 20px;
    }
}