*{
    padding: 0;
    margin: 0;
}

#IntakeFormSmallContainer{
    width: 70vw;
    margin: 1vh auto;
    background-color: rgba(70,90,135,255);
    border: 1vh solid white;
    border-radius: 5vh;
    min-height: max-content;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    box-shadow: inset 0 5px 15px -10px rgba(0, 0, 0, 0.5), 0 2px 15px 0.5px rgba(0, 0, 0, 0.5);
    padding: 3vh 0 5vh 0;
    position: relative;
    z-index: 5;

}

#IntakeFormHeader{
    font-size: 7vh;
    text-align: center;
    font-weight: 1000;
    padding: 0 0 2vh 0;
}

#IntakeFormDescription{
    font-size: 5vh;
    margin: auto;
    width: 80%;
    text-align: center;
}

#IntakeFormMainContainer{
    padding-bottom: 30px;
}

#IntakeFormButton{
    color: rgba(70,90,135,255);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: white;
    padding: 2vh 4vw 2vh 4vw;
    border: none;
    border-radius: 100px;
    font-size: 3.5vh;
    display: block;
    margin: 9vh auto 1vh auto;
    font-weight: 600;
    box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.5);
}

#IntakeFormButtonNew{
    color: rgba(70,90,135,255);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: white;
    padding: 7px 18px;
    border: none;
    border-radius: 100px;
    font-size: 18px;
    display: block;
    /* margin: 9vh auto 1vh auto; */
    font-weight: 600;
    box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.5);
}

button{
    cursor: pointer;
}

.IntakeFormSelect{
    /* padding: 0 1.7em 0 1.7em; */
    margin: 0 0.7em 0 0.7em;
}

.IntakeFormSelect option, .IntakeFormSelect option:checked{
    font-size: 4.4vh;
    background-color: rgba(70,90,135,255);
    border: none;
    color: white;
    outline: none;
    text-align: left;
}

@media screen and (max-width: 900px){
    #IntakeFormSmallContainer{
        width: 95%;
        margin: -30px auto 50px auto;
        background-color: rgba(70,90,135,255);
        border: 1vh solid white;
        border-radius: 5vh;
        min-height: max-content;
        color: white;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        box-shadow: inset 0 5px 15px -10px rgba(0, 0, 0, 0.5), 0 2px 15px 0.5px rgba(0, 0, 0, 0.5);;
        padding: 10px 5px 20px 5px;
    }


    #IntakeFormDescription{
        font-size: 3vh;
        margin: auto;
        width: 100%;
        text-align: center;
    }

    #livingPlace{
        width: 6ch;
        display: inline-block;
    }

    #appointmentDate{
        width: 90%;
        display: inline;
    }

    #maxDistance{
        width: 30%;
        display: inline;
    }

    #IntakeFormButton{
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 5px 15px 5px 15px;
    }
}