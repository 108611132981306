#bookingNotice {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: bold;
  text-align: center;
  padding-top: 2rem;
  color: rgba(70, 90, 135, 255);
}

#userCurrentApptContainer {
  margin: 3vh auto 5vh auto;
  padding-right: 3vh auto 5vh auto;
  width: 90vw;
  height: max-content;
  min-height: 190px;
  border: 3px solid rgba(109, 147, 230, 255);
  border-radius: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 2vh;
  background-color: rgba(70, 90, 135, 255);
}

#userCurrentApptContainerNew {
  padding: 30px 20px;
  border: 3px solid rgba(109, 147, 230, 255);
  border-radius: 3vh;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  color: white;
  background-color: rgba(70, 90, 135, 255);
}

#userCurrentApptContainerNew2 {
  padding: 30px 20px;
  border: 3px solid rgba(109, 147, 230, 255);
  border-radius: 3vh;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  color: white;
  background-color: rgba(70, 90, 135, 255);
}
#userCurrentApptHeader,
#newlyFoundApptHeader {
  margin: 1rem 0;
  font-weight: bolder;
  font-size: 24px;

}

#userCurrentApptDescription {
  position: absolute;
  display: flex;
  justify-content: space-around;
  width: max-content;
  margin-left: 25vw;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
}

#userCurrentApptDescription div {
  margin: 2vh 0 2vh 0;
}

#userDetails {
  margin: 20px 0px;
  padding-left: 20px;
}

#userDetails li {
  font-size: 1.4em; /* Adjust as needed */
}


#newlyFoundApptContainer {
  border: 3px solid rgba(70, 90, 135, 255);
  height: max-content;
  border-radius: 3vh;
  display: flex;
  align-items: center;
  column-gap: 36px;
  color: white;
  padding: 30px 20px;
  background-color: rgba(109, 147, 230, 255);
}

#newlyFoundApptDescription {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
}



#BookPageDisclaimer {
  width: 92%;
  margin: 3vh auto 2vh auto;
}

#BetaNotice {
  text-decoration-color: red;
  font-weight: bolder;
}

.purchaseButton {
  width: auto;
  text-decoration-color: white;
  cursor: pointer;
}

.receive-form-button {
  font-size: 2.5vh; /* Adjust font size as needed */
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 2vh; /* Adjust border-radius as needed */
  margin-top: 2vh; /* Space above the button */
  background-color: white;
  color: rgba(70, 90, 135, 1);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto; /* Auto width based on content */
  min-width: 120px; /* Minimum width to avoid squishing */
  flex-shrink: 0; /* Prevent the button from shrinking */
}

.receive-form-button:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}

#BookPageBackgroundContainer {
  background: url("../img/book_page_background.jpg");
  background-size: 100%;
  /* background-repeat: no-repeat; */
  background-position: center 70%;
  box-shadow: inset 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  padding-top: 70px;
}

#BookPagePurchaseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  padding: 25px 0px;
}
.downloadForm {
  width: 20% !important;

}
.downloadForm button {
  background-color: #efefef;
  align-items: center;
}

@media screen and (max-width: 600px) {
  #userCurrentApptContainer {
    margin: 20px auto 0 auto;
    width: 90%;
    height: max-content;
    /* min-height: 0px; */
    border: 3px solid rgba(109, 147, 230, 255);
    border-radius: 3vh;
    display: block;
    color: white;
    padding: 2vh;
    background-color: rgba(70, 90, 135, 255);
  }
  #userCurrentApptContainerNew {
    padding: 20px 10px;
    /* min-height: 0px; */
    border: 3px solid rgba(109, 147, 230, 255);
    border-radius: 3vh;
    display: block;
    color: white;
    background-color: rgba(70, 90, 135, 255);
  }
  #userCurrentApptContainerNew2 {
    padding: 20px 10px;
    /* min-height: 0px; */
    border: 3px solid rgba(109, 147, 230, 255);
    border-radius: 3vh;
    color: white;
    background-color: rgba(70, 90, 135, 255);
  }
  #userCurrentApptDescription {
    position: relative;
    margin-left: 0px;
  }
  #newlyFoundApptContainer {
    border: 3px solid rgba(70, 90, 135, 255);
    border-radius: 3vh;
    color: white;
    display: block;
    background-color: rgba(109, 147, 230, 255);
  }


  #userCurrentApptHeader,
  #newlyFoundApptHeader {
    font-weight: 1000;
    font-size: 20px;
    width: 100%; /* Take full width to avoid squishing */
    text-align: center; /* Center the text */
  }

  #userCurrentApptDescription {
    width: 100%; /* Take full width */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    font-size: 3.5vh;
    font-weight: 600;
  }

  #userCurrentApptDescription div {
    margin: 10px 0 0 0;
  }

  #userCurrentApptDescription div div {
    margin: 5px 0 5px 0;
  }

  #BookPageBackgroundContainer {
    background: url("../img/book_page_background.jpg");
    background-size: 150%;
    /* background-repeat: no-repeat; */
    background-position: center 0%;
    box-shadow: inset 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    padding-top: 110px;
  }

  #bookingNotice {
    font-size: 6vh;
    font-weight: 1000;
    text-align: center;
    padding: 5px 0 0 0;
    color: rgba(70, 90, 135, 255);
  }

  #newlyFoundApptContainer {
    margin-bottom: 0;
  }

  #BookPageDisclaimer {
    margin-top: 7px;
    font-size: 10px;
    text-align: justify;
  }
  .downloadForm {
    width: 75% !important;
  }
}
