*{
    padding: 0;
    margin: 0;
}

#MainBackgroundContainer{
    background-size: 140%;
    background-repeat: no-repeat;
    background-position: center 70%;
    /* padding-right: 15%; */
    height: 85vh;
    box-shadow: inset 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
}

#priorityBackground{
    color: rgba(70,90,135,255);
}

#scanBackground{
    color: rgba(109,147,230,255);
    line-height: 0.5;
}

#priorityBackground, #scanBackground{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 1000;
    font-size: 17vh;
}

#textContainer{
    padding: 6vh 0 0 10vw;
}

#descriptionBackground{
    color: rgba(70,90,135,255);
    margin-top: 8vh;
    font-size: 4vh;
    font-weight: 500;
}

#buttonBackground{
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: rgba(70,90,135,255);
    padding: 3vh 2.5vw 3vh 2.5vw;
    border: none;
    border-radius: 100px;
    font-size: 3.5vh;
    margin-top: 4vh;
}

button{
    cursor: pointer;
}

@media screen and (max-width: 900px){
    #MainBackgroundContainer{
        background-size: 250%;
        background-repeat: no-repeat;
        background-position: center 0%;
        height: 500px;
        box-shadow: inset 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
    }

    #priorityBackground{
        color: rgba(70,90,135,255);
        font-size: 50px;
    }

    #scanBackground{
        color: rgba(109,147,230,255);
        line-height: 0.5;
        font-size: 50px;
    }

    #descriptionBackground{
        color: rgba(70,90,135,255);
        margin-top: 40px;
        font-size: 4vh;
        font-weight: 500;
    }
    #descriptionTagline{
        color: rgba(70,90,135,255);
        margin-top: 5px;
        font-size: 2vh;
        font-weight: 500;
    }


    #textContainer{
        padding: 25px 0 0 20px;
    }

    #buttonBackground{
        color: white;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background-color: rgba(70,90,135,255);
        padding: 10px 20px 10px 20px;
        /* padding: 3vh 2.5vw 3vh 2.5vw; */
        border: none;
        border-radius: 100px;
        font-size: 3.5vh;
        margin-top: 50px;
    }
}