.IntakeFormInput{
    /* font-size: 5vh; */
    font-size: 4.4vh;
    background: transparent;
    border: none;
    border-bottom: 3px solid white;
    color: white;
    outline: none;
    text-align: center;
}

#maxDistance{
    width: 5vw;
}

#livingPlace{
    width: 14vw;
}

.IntakeFormInput::placeholder{
    color: rgba(255, 255, 255, 0.67);
}

@media screen and (max-width: 900px){
    .IntakeFormInput{
        font-size: 3vh;
    }

    #maxDistance{
        width: 50px;
    }

    #livingPlace{
        width: 300px;
    }

    #appointmentDate{
        width: 200px;
    }
}