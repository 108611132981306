* {
  padding: 0;
  margin: 0;
}

body {
  min-height: 99vh;
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 0.1rem; /* Adjust the width as needed */
}

/* Hide the horizontal scrollbar */
::-webkit-scrollbar:horizontal {
  height: 0.1rem; /* Adjust the height as needed */
}

/* Handle (thumb) of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make the scrollbar thumb invisible */
}

.main-wrap{
  border-bottom: 0.75px solid rgb(141, 141, 141);
}

.customList li {
  list-style-type: none;
}

.customList {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: rgba(70, 90, 135, 255);
  display: flex;
  justify-content: space-between;
  /* justify-items: end; */
  gap: 25px;
  padding: 5vh 0 4vh 0;
  /* width: 100%; */
  margin: 0;
  min-height: max-content;
  align-items: center;
}

#bookBlock {
  /* margin-left: 20%; */
  font-size: 16px;
}

#containerMenu {
  width: 100%;
}

#priority,
#scan {
  font-weight: 1000;
  font-size: 6vh;
}

.linkMenu {
  text-decoration: none;
  color: rgba(70, 90, 135, 255);
}

#priority {
  color: rgba(70, 90, 135, 255);
}

#scan {
  color: rgba(109, 147, 230, 255);
}

#container-for-lins-photo {
  min-width: 1em;
  display: inline-block;
}

#linsPhoto {
  margin: -1vh 0 0 0;
  height: 1em;
}

#searchAndLins {
  display: flex;
}

/* .linkMenu :active{
    list-style: none;
    text-decoration: none;
    color: white;
} */

.weight {
  font-weight: 600;
}

.headerText {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: 6vh;
  color: rgba(70, 90, 135, 255);
}

.descriptionText {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 200;
  font-size: 4vh;
  text-indent: 20px;
  width: 95vw;
  padding-left: 0 2vw 0 2vw;
  padding-top: 3vh;
}

#menu-logo-photo {
  margin-left: 5vh;
}

#menu-logo-photo a img {
  width: 250px;
  /* height: 34px; */
}

#burger-menu-button {
  display: none;
}

#burger-menu-mobile-version-container {
  display: none;
}

@media screen and (max-width: 900px) {
  .customList {
    display: block;
    padding: 10px 0 0 0;
    border: none;
    display: flex; /* Add this to make it a flex container */
    align-items: center;
    /* border-bottom: 0.5px solid rgba(141, 141, 141, 0.475); */
  }

  .customList li,
  #bookBlock,
  #menu-block-login-or-signup-link {
    list-style-type: none;
    width: max-content;
    margin: -0.5em 20px 0 auto;
    margin-left: auto;
  }

  #menu-logo-photo {
    margin-left: 0;
    min-height: 34px;
    display: flex;
    align-items: center; /* Align the logo vertically */
  }

  #menu-logo-photo a img {
    /* width: 90vw; */
    /* margin: 20px 0 0 5vw; */
    height: 34px;
  }

  .weight,
  .linkMenu {
    display: none;
  }
  #burger-menu-button {
    display: block;
  }
  #burger-menu-mobile-version-container {
    display: block;
  }
  #google_translate_element {
    position: absolute;
    top: 85px !important;
    left: 15px !important;
  }
}
