/* Bootstrap container adjustments for the custom styling */
.container {
  max-width: 100%;
  padding: 0;
  margin: 0 auto;
}

/* Row adjustments for the custom styling */
.row {
  margin-right: 0;
  margin-left: 0;
}

/* Style for the container of the time saved information */
#timesaveContainer {
  background-color: #1f2c39; /* Dark background similar to the image */
  border-radius: 20px; /* Slightly rounded corners for the container */
  color: white;
  font-family: "Segoe UI", sans-serif; /* Cleaner font that resembles digital displays */
  padding: 2vh; /* Uniform padding */
  margin-top: 40px;
  text-align: center; /* Center the text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft box shadow for depth */
}

/* Style for the headings */
#timesaveContainer h2 {
  font-size: 28px; /* Size of the heading */
  margin-bottom: 1rem; /* Space below the heading */
  color: #e0e0e0; /* Light grey color for headings */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8); /* Text shadow for depth */
}

/* Style for the label showing the time format */
.time-label {
  /* background-color: #38B2AC; A color similar to the teal in the image */
  border-radius: 10px; /* Rounded corners */
  /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3); Inset shadow for an inner glow effect */
  padding: 1rem 2rem; /* Padding */
  font-size: 1rem; /* Font size for the label */
  margin-top: 1rem; /* Space above the label */
  display: inline-block; /* Make the label an inline block to allow padding */
  color: darkgray; /* White color for better readability */
}

@media screen and (max-width: 900px) {
  #timesaveContainer h2 {
    font-size: 20px; /* Size of the heading */
  }
  .timesaveContainerBox {
    margin: 3.5rem !important;
  }
}
