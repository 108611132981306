@media screen and (max-width: 900px) {

        .bm-menu.bm-slide.right {
            right: 0%;
        }

        .burger-menu-item{
            text-decoration: none;
            color: black;
            font-size: 20px;
            /* background-color: rgba(255, 255, 255, 0.322); */
            line-height: 1;
            margin: 15px 0px;
            text-align: center;
        }

        .burger-menu-item:active{
            text-decoration: none;
            color: black;
            font-size: 30px;
            /* background-color: rgba(255, 255, 255, 0.322); */
            line-height: 1;
            margin: 10px 0 10px 0;
            text-align: center;
        }

        #burger-menu-id{
            background: rgb(255 255 255 / 90%);
            width: 100%;
        }

        #burger-menu-button{
            background: transparent;
            border: none;
            font-size: 40px;
            width: max-content;
            display: block;
        }
/*
        #burger-menu-container-in-file{
            height: 50px;
            border: 1px solid black;
            width: 50px;
        } */
        .bm-burger-button {
            display: none;
            position: fixed;
            width: 36px;
            height: 30px;
            left: 36px;
            top: 36px;
          }

          /* Color/shape of burger icon bars */
          .bm-burger-bars {
            background: #373a47;
          }

          /* Color/shape of burger icon bars on hover*/
          .bm-burger-bars-hover {
            background: #a90000;
          }

          /* Position and sizing of clickable cross button */
          .bm-cross-button {
            height: 24px;
            width: 24px;
            display: none;
          }


          /* Color/shape of close button cross */
          /* .bm-cross {
            background: #bdc3c7;
          } */


          /*
          Sidebar wrapper styles
          Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
          */
          .bm-menu-wrap {
            position: fixed;
            height: 100%;
          }

          /* General sidebar styles */
          /* .bm-menu {
            background: #373a47;
            padding: 2.5em 1.5em 0;
            font-size: 1.15em;
          } */

          /* Morph shape necessary with bubble or elastic */
          .bm-morph-shape {
            fill: #373a47;
          }

          /* Wrapper for item list */
          .bm-item-list {
            color: #b8b7ad;
            padding: 0.8em;
          }

          /* Individual item */
          .bm-item {
            display: inline-block;
          }

          /* Styling of overlay */
          .bm-overlay {
            background: rgba(0, 0, 0, 0.3);
          }

          .bm-menu.bm-slide.right {
            right: 0;
            width: 100%; /* Set the width to 100% */
          }
}
