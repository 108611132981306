*{
    padding: 0;
    margin: 0;
}

#top_title{
    padding-top: 55px;
}

#SubheaderBackgroundContainer{
    /* background-size: 140%; */
    /* background-repeat: no-repeat; */
    padding-top: 40px;
    background-position: center 70%;
    /* height: 35vh; */
    box-shadow: inset 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
    /* padding-right: 10%;
    padding-bottom: 3%; */
    position: relative;
    z-index: 2;

}

#betaTesting{
    color: red;
    /* font-size: 4vh; */
    font-weight: bolder;
    /* text-decoration: underline; */
}

#textContainer{
    padding: 6vh 0 0 10vw;
}

#descriptionBackgroundSH{
    color: rgba(70,90,135,255);
    /* font-size: 2.75vh; */
    font-weight: 500;
}

@media screen and (max-width: 900px){
    #MainBackgroundContainer{
        background-size: 250%;
        background-repeat: no-repeat;
        background-position: center 0%;
        height: 500px;
        box-shadow: inset 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
    }

    #SubheaderBackgroundContainer,
    #IntakeFormSmallContainer {
        width: 95%;
        margin: 2vh auto; /* Adjust the margin to maintain spacing */
        /* ... other responsive styles ... */
    }

    #betaTesting{
        color: red;
        /* font-size: 2.25vh; */
        font-weight: bolder;
        /* text-decoration: underline; */
    }

    #descriptionBackgroundSH{
        color: rgba(70,90,135,255);
        /* margin-top: 2vh;
        font-size: 1.5vh; */
        font-weight: 500;
    }

    #textContainer{
        padding: 25px 0 0 20px;
    }

}