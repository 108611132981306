#faq-page-main-container{
    padding: 80px 0 0 2vh;
}

.faq-page-question{
    font-size: 5vh;
}

.faq-page-answer{
    font-size: 3vh;
}

@media screen and (max-width: 900px) {
    #faq-page-main-container{
        padding: 110px 30px 0 10px;
    }

    .faq-page-question{
        font-size: 4vh;
    }
    
    .faq-page-answer{
        font-size: 3vh;
    }
}