/* BlogPostStyles.css */
.blog-container {
  max-width: 1000px; /* Adjust as needed for your layout */
  margin: auto;
  padding: 2rem;
}

.blog-title {
  font-size: 3rem; /* Large, eye-catching title */
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.blog-metadata {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.blog-metadata img {
  border-radius: 50%; /* Make the author image round */
  margin-right: 0.5rem;
}

.blog-metadata span {
  color: #555; /* Grey text for the date and author name */
  font-size: 0.9rem;
}

.blog-content {
  line-height: 1.6;
  color: #333; /* Darker text for readability */
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.blog-content h2 {
  font-size: 2rem; /* Adjust size as needed */
  color: #333; /* Dark text for readability */
  margin-top: 2rem; /* Space above the header */
  margin-bottom: 1rem; /* Space below the header */
  padding-bottom: 0.5rem; /* Padding for aesthetic */
  border-bottom: 2px solid #eaeaea; /* A subtle line under the header */
}

.blog-content hr {
  border: 0;
  height: 1px;
  background: #eaeaea; /* Color for the horizontal line */
  margin-top: 2rem; /* Space above the line */
  margin-bottom: 2rem; /* Space below the line */
}

.blog-content p {
  font-size: 1rem; /* Adjust size as needed */
  line-height: 1.8;
  margin-bottom: 1rem; /* Space between paragraphs */
  color: #444; /* Slightly lighter color for readability */
}

/* Example styles for Table of Contents */
.toc-container {
  font-size: 1rem;
  color: #555;
}

.toc-item {
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.toc-item:hover {
  text-decoration: underline;
}
