* {
  padding: 0;
  margin: 0;
}

#mainContainer {
  background-color: rgba(70, 90, 135, 255);
  color: white;
  font-weight: 500;
  letter-spacing: 0.1em;
  font-size: 3.5vh;
  text-align: center;
  padding: 0.2em 10px 0.5em 10px;
}

#alert-main-container-in-header {
  position: absolute;
  width: 100%;
  /* height: 100%; */
}

@media screen and (max-width: 900px) {
  #mainContainer {
    display: none;
  }
}

#loggedInMsg {
  position: absolute;
  top: 12px;
  right: 20px;
  color: #fff;
}
#google_translate_element{
  position: absolute;
  top: 0px;
  right: 350px;
}

@media screen and (max-width: 600px) {
  #loggedInMsg {
    right: 20px; /* Adjust for smaller screens */
  }

  #google_translate_element{
    right: 0px;
  }
}