#sign-up-main-container{
    text-align: center;
    /* border: 1px solid black; */
    width: 50vw;
    margin: 3vh auto auto auto;
    padding: 2vh;
    padding-top: 70px;
}

#first-string-sign-up-page{
    font-size: 5vh;
}

#second-string-sign-up-page{
    font-size: 2.2vh;
}

.input-sign-up{
    font-size: 18px;
    margin: 10px 0 0 0;
    padding-left: 1vw;
    height: 40px;
}

#bottom-text-sign-up-page{
    font-size: 2.2vh;
}

@media screen and (max-width: 900px) {
    #sign-up-main-container{
        text-align: center;
        /* border: 1px solid black; */
        padding: 2vh;
        margin: 20px 0 0 0;
        width: 100%;
    }

    #first-string-sign-up-page{
        line-height: 0.9em;
        margin-bottom: 10px;
    }

    #sign-up-sign-up-button{
        width: 200px;
        font-size: 24px;
        padding: 10px 0 10px 0;
    }
}